import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { toast } from './components/toast';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyBF_vv2yELgQDUtklRSew-Baz1_Du7teTw',
    authDomain: 'westlincolnfpo.firebaseapp.com',
    projectId: 'westlincolnfpo',
    storageBucket: 'westlincolnfpo.appspot.com',
    messagingSenderId: '161501408581',
    appId: '1:161501408581:web:0d98d31bd5e105ed89622c',
    measurementId: 'G-LF701WWCJM',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
export default firebaseApp;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export async function loginUser(email, password) {
    try {
        const res = await firebase.auth().signInWithEmailAndPassword(email, password);
        console.log(res);
        return true;
    } catch (error) {
        toast(error.message, 4000);
        return false;
    }
}

export function logoutUser() {
    return firebase.auth().signOut();
}
